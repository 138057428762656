import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import NProgress from 'nprogress';
import Meta from 'vue-meta';
import AOS from 'aos';
import 'aos/dist/aos.css';
import VueParallaxJs from 'vue-parallax-js';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import VueAnalytics from 'vue-ua';
import i18n from './i18n';
import App from './App.vue';
import router from './router';
import store from './store';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '../node_modules/nprogress/nprogress.css';

// import vueConfig from '../vue.config';
Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Meta);
Vue.use(VueParallaxJs);
Vue.use(VueI18n);
Vue.use(VueAnalytics, {
  appName: 'DAO Egypt', // Mandatory
  appVersion: '2.5.0', // Mandatory
  trackingId: 'UA-222273353', // Mandatory
  debug: true, // Whether or not display console logs debugs (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  ignoredViews: ['booking'], // If router, you can exclude some routes name (case insensitive) (optional)
  trackPage: true, // Whether you want page changes to be recorded as pageviews
  // (website) or screenviews (app), default: false
  createOptions: {
    // Optional, Option when creating GA tracker, ref: https://developers.google.com/analytics/devguides/collection/analyticsjs/field-reference
    siteSpeedSampleRate: 10,
  },
  // globalDimensions: [
  //   // Optional
  //   { dimension: 1, value: 'MyDimensionValue' },
  //   { dimension: 2, value: 'AnotherDimensionValue' },
  // ],
  // globalMetrics: [
  //   // Optional
  //   { metric: 1, value: 'MyMetricValue' },
  //   { metric: 2, value: 'AnotherMetricValue' },
  // ],
});

new Vue({
  created() {
    AOS.init({
      disable: window.innerWidth < 768,
    });
  },
  i18n,
  router,
  store,
  components: {},
  render: (h) => h(App),
}).$mount('#app');

router.beforeResolve((to, from, next) => {
  if (to.name) {
    NProgress.start();
  }
  next();
});

// eslint-disable-next-line no-unused-vars
router.afterEach((to, from) => {
  NProgress.done();
});
